/* globals $ */

import "regenerator-runtime/runtime";

import jQuery from "jquery";
global.$ = global.jQuery = jQuery;

import 'bootstrap';

import "datatables.net";
import dt from 'datatables.net-bs';
dt(window, $);
import "lightslider";
import "angular";
import "angular-utils-pagination";

import '../css/main.scss';

function CreateAndBindSliders() {
	$(".item-slider").each(function (k, v) {
		var carousel = $(v).find('.featured-carousel');
		var prevBtn = $(v).find('.slider-btn-left');
		var nextBtn = $(v).find('.slider-btn-right');

		if (carousel) {
			var slider = carousel.lightSlider({
				controls: false,
				item: 4,
				pager: false,
				enableDrag: false,
				auto: true,
				responsive: [
					{
						breakpoint: 992,
						settings: {
							item: 4,
						}
					},
					{
						breakpoint: 901,
						settings: {
							item: 3,
						}
					},
					{
						breakpoint: 768,
						settings: {
							item: 2,
						}
					},
					{
						breakpoint: 576,
						settings: {
							item: 2
						}
					},
					{
						breakpoint: 520,
						settings: {
							item: 1
						}
					}
				]
			});

			if (slider) {
				if (prevBtn) {
					prevBtn.click(function () {
						slider.goToPrevSlide();
						clearInterval(slideInterval);
					});
				}

				if (nextBtn) {
					nextBtn.click(function () {
						slider.goToNextSlide();
						clearInterval(slideInterval);
					});

				}
			}
		}
	});
}

function HideHeaderArea(btn, id, area) {
	if (btn.id !== id && $(btn).parent().attr('id') !== id) {
		$(area).hide();
	}
}

$(function () {
	setTimeout(() => CreateAndBindSliders(), 600);

	$('#agree-button').click(function () {
		$('input[name=license-agreement]').prop('checked', true);
	});

	$('#disagree-button').click(function () {
		$('input[name=license-agreement]').prop('checked', false);
	});

	$('#profile-user-btn').click(function () {
		$('#profile-dropdown').show();
	});

	$('#hyland-apps-btn').click(function () {
		$('#hyland-apps-dropdown').toggle();
	});

	$('html').click(function (ev) {
		HideHeaderArea(ev.target, 'hyland-apps-btn', '#hyland-apps-dropdown');
		HideHeaderArea(ev.target, 'profile-user-btn', '#profile-dropdown');
	});

	$('#dtBasicExample').DataTable({
		"paging": false // false to disable pagination (or any other option)
	});

	$('#tracking-table').DataTable();

	$('.dataTables_length').addClass('bs-select');


});
